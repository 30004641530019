//@ts-nocheck
import React, { lazy, Suspense,useEffect } from 'react';
import './MenuPage.css';
import Header from './Header';

const Sidebar = ({ activeItem, onItemClick }) => {
  const menuItems = [
    { id: 1, label: 'ACORD', color: '#0078D4', component: 'StepperComp' },
    { id: 2, label: 'SOV', color: '#0078D4', component: 'StepperCompSOV' },
    { id: 3, label: 'LR', color: '#0078D4', component: 'StepperCompLR' },
    { id: 4, label: 'LR++', color: '#0078D4', component: 'StepperCompLRPlus' },
  ];

  const [currentActiveItem, setCurrentActiveItem] = React.useState(activeItem);

  const loadComponent = async (component) => {
    const module = await import(`./${component}`);
    return module.default;
  };

  const handleItemClick = async (item) => {
    setCurrentActiveItem(item.id);
    const Component = await loadComponent(item.component);
    onItemClick(<Component />);
  };

  return (
    <div className="sidebar">
      {menuItems.map((item) => (
        <div key={item.id} style={{ marginBottom: '3.5rem' }}>
          <button
            style={{
              color: 'white',
              fontWeight: 'bold',
              backgroundColor: item.color,
              height: '2.5rem',
              width: '4.5rem',
              borderTopRightRadius: '1.3rem',
              borderBottomRightRadius: '1.3rem',
              cursor: 'pointer',
            }}
            className={`menu-item ${currentActiveItem === item.id ? 'active' : ''}`}
            
            onClick={() => handleItemClick(item)}
          >
            <div style={{ paddingTop: '0rem' }}>
              <span style={{ marginTop: '3rem' }}>{item.label}</span>
            </div>
          </button>
        </div>
      ))}
    </div>
  );
};

const MainPage = () => {
  const [selectedComponent, setSelectedComponent] = React.useState(null);
  const loadDefaultComponent = async () => {
    const Component = await import('./StepperComp');
    setSelectedComponent(<Component.default />);
  };

  useEffect(() => {
    loadDefaultComponent();
  }, []); // Load default component on initial mount

  const handleItemClick = (component) => {
    setSelectedComponent(component);
  };

  return (
    <>
     <Header />
    <div className="main-page">
      <div className="container">
        <div className="menu-container">
          <Sidebar activeItem={1} onItemClick={handleItemClick} />
        </div>
        <div className="content-container">
          {selectedComponent && <Suspense fallback={<div>Loading...</div>}>{selectedComponent}</Suspense>}
        </div>
      </div>
    </div>
    </>
  );
};

export default MainPage;
