//@ts-nocheck
import React, { useState, useMemo } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import * as XLSX from 'xlsx';
import './ExcelUploadPreview.css';
import AWS from 'aws-sdk';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { S3 } from 'aws-sdk';
import Box from '@mui/material/Box';
import FadeLoader from "react-spinners/FadeLoader";
import { OutTable, ExcelRenderer } from 'react-excel-renderer';

function ExcelUploadPreview() {
  const [sheets, setSheets] = useState([]);
  const [selectedSheetIndex, setSelectedSheetIndex] = useState(null);
  const [excelData, setExcelData] = useState({ cols: [], rows: [] });
  const [pdfFile, setPdfFile] = useState('');
  const [tabs, setTabs] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(null);

  const [process, setProcess] = useState(true);

  const [download, setDownload] = useState(true);
  const [loading, setLoading] = useState(false);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [filterOptions, setFilterOptions] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const handleCheckboxChange = (rowIndex) => {
    const selectedIndex = selectedRows.indexOf(rowIndex);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, rowIndex);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
  };

  const handleSortClick = (columnKey) => {
    const direction = sortConfig.key === columnKey && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key: columnKey, direction });
  };

  const sortedRows = useMemo(() => {
    if (!sortConfig.key) return excelData.rows;
    const sortedData = [...excelData.rows];
    sortedData.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
    return sortedData;
  }, [excelData.rows, sortConfig]);

  const dropzoneTextStyles = {
    color: 'red !important'
  };

  const handleFileS3 = async (file) => {
    if (!file) {
      console.error('No file selected.');
      return;
    }

    // Replace 'your-s3-bucket-name' with your actual S3 bucket name
    const bucketName = 'openaiform-poc';
    const region = 'us-east-1'; // e.g., 'us-east-1'

    const s3 = new S3({
      region,
      accessKeyId: 'AKIA3IXQ24EIEETLGEW5',
      secretAccessKey: 'R8qNDv/fuIrgO3uff09pygEOPJL1DYqDeiu2nuD4',
    });

    // Generate a pre-signed URL for the S3 object
    const params = {
      Bucket: bucketName,
      Key: `${'insurance_poc'}/${file.name}`,
      ContentType: file.type,
    };

    try {
      const url = await s3.getSignedUrlPromise('putObject', params);

      // Use the generated URL to upload the file directly to S3
      await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      });

      console.log('File uploaded successfully!');
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleProcess = () => {
    setLoading(true)
    AWS.config.update({
      // accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      // secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      accessKeyId: 'AKIA3IXQ24EIEETLGEW5',
      secretAccessKey: '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',
      // region: 'us-east-1',
    });

    // Create S3 service object
    const s3 = new AWS.S3();





    (async () => {
      const [response] = await Promise.all([
        fetch(
          // "https://abvlkw5amiqneknudxvjvb3hte0aoyxi.lambda-url.us-east-1.on.aws",
          "https://mngcfb77pp322sriffp7vx2sie0xnhjq.lambda-url.us-east-1.on.aws/",
          {
            method: "POST",
            // 'X-API-KEY': '',
            headers: {
              //'X-API-KEY': Config.XAPIKEY,
              'Accept': "application/json",
              'Content-Type': "application/json"
            },
            // body: JSON.stringify({
            //   "path": "s3://openaiform-poc/Hathorne Green SOV.xlsx - 17 KB.XLSX"
            // })
            body: JSON.stringify({ "product_source": "s3://openaiform-poc/insurance_poc/ExampleProducts.xlsx", "records_source": "s3://openaiform-poc/insurance_poc/ExampleRecords.xlsx", "openai_secret": "Azure_OpenAI_Key" })
          })
      ]);
      var data = {};
      const content = await response.json();
      console.log(content)
      const s3Path = content['output_filepath'];

      // Split the path string by '/'
      const pathParts = s3Path.split('/');

      // Get the index of the bucket name (which is after 's3://' and before the first '/')
      const bucketIndex = pathParts.findIndex(part => part === 'openaiform-poc');

      // Get the path after the bucket name
      const trimmedPath = pathParts.slice(bucketIndex + 1).join('/');

      console.log(trimmedPath);
      // Parameters for getObject operation
      const params = {
        Bucket: 'openaiform-poc',
        Key: trimmedPath,
      };
      //setLoading(false);
      //data["count"] = content;
      // Retrieve the Excel file from S3
      s3.getObject(params, (err, data) => {
        if (err) {
          console.error(err);
          return;
        }

        // Parse Excel file data
        const workbook = XLSX.read(data.Body, { type: 'buffer' });
        const sheetName = workbook.SheetNames[0]; // Assuming only one sheet is present
        const worksheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Extract column headers
        const cols = sheetData[0] ? sheetData[0].map((col, index) => ({ key: index, label: col })) : [];

        // Remove column headers from data
        const rows = sheetData.slice(1);

        // Set Excel data
        setExcelData({ cols, rows });

        setLoading(false)
        setDownload(false)
      });
    })();






  };


  const handleTabClick = (index) => {
    setActiveTabIndex(index);
    setSelectedSheetIndex(0); // Reset selected sheet index when switching tabs
  };

  const handleTabClose = (index) => {
    const updatedTabs = [...tabs];
    updatedTabs.splice(index, 1);
    setTabs(updatedTabs);
    if (activeTabIndex === index) {
      setActiveTabIndex(null);
    } else if (activeTabIndex > index) {
      setActiveTabIndex(activeTabIndex - 1);
    }
  };

  const handleFileUpload = (files) => {
    // Check if files array is not empty
    if (files.length === 0) {
      console.error("No files uploaded.");
      return;
    }

    setProcess(false)
    // Get the last uploaded file
    const file = files[files.length - 1];

    // Check if the file is a Blob
    if (!(file instanceof Blob)) {
      console.error("Invalid file format. Please upload a valid Excel file.");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      setPdfFile(e.target.result);
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetNames = workbook.SheetNames;
      const sheetsData = sheetNames.map((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        const sheetData = [];
        for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
          const row = [];
          for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
            const cellAddress = { r: rowNum, c: colNum };
            const cellRef = XLSX.utils.encode_cell(cellAddress);
            const cell = worksheet[cellRef];
            const cellValue = cell ? cell.v : ''; // Use empty string for empty cells
            row.push(cellValue);
          }
          sheetData.push(row);
        }
        return {
          name: sheetName,
          data: sheetData
        };
      });

      // Create a new tab representing the uploaded file
      const newTab = {
        fileName: file.name, // Set the file name
        sheets: sheetsData, // Set the sheet data
        activeSheetIndex: null // Set the active sheet index (initially null)
      };

      // Add the new tab to the existing tabs
      setTabs((prevTabs) => [...prevTabs, newTab]);

      // Set the active tab to the index of the last added tab
      setActiveTabIndex(prevTabs => prevTabs ? prevTabs.length : 0);
      // Reset selected sheet index
      setSelectedSheetIndex(0);
      handleFileS3(file)
    };

    reader.readAsArrayBuffer(file);
  };


  const handleSheetClick = (index) => {
    setSelectedSheetIndex(index);

  };
  const handleDownload = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    // Add a new sheet
    const wsData = excelData.rows;
    const wsHeaders = excelData.cols.map(col => col.label);
    const ws = [wsHeaders, ...wsData];
    const wsSheet = XLSX.utils.aoa_to_sheet(ws);
    XLSX.utils.book_append_sheet(wb, wsSheet, "Summarized Claims");

    try {
      // Save the workbook to a file
      XLSX.writeFile(wb, "SummarizedClaims.xlsx");
    } catch (error) {
      console.error("Error occurred while downloading the Excel file:", error);
    }
  };

  return (
    <div className="excel-upload-preview-container">
      <div className="flex flex-row ml-2" style={{ width: '91.2rem', marginLeft: "2rem", marginTop: '1.2rem' }}>
        <p style={{
          color: 'white', fontSize: '0.95rem',
          width: '14rem', marginTop: '-0.15rem', fontWeight: 'bold'
        }}>
          Upload Document:</p>
        <div className="flex flex-row" style={{
          width: '48rem', marginLeft: '-6.5rem'
        }}>
          <DropzoneArea sx={{
            "& .MuiDropzoneArea-textContainer": {
              width: '10rem !important'
            }
          }}
            onChange={handleFileUpload}
            filesLimit={2}
            acceptedFiles={['.xlsx', '.xls']}
            showFileNames={false}
            dropzoneText="Drag and drop files here"
            dropzoneParagraphClass={dropzoneTextStyles}
          />
        </div>
        <button onClick={handleProcess} disabled={tabs.length !== 2}
          style={{
            width: '7rem',
            borderRadius: '0.2rem', height: '2rem',
            marginTop: '-0.4rem', marginLeft: '-15rem', cursor: 'pointer',
            color: "white", backgroundColor: tabs.length !== 2 ? "grey" : "#86bc26", fontWeight: 'bold'
          }}>Process</button>
        <button onClick={handleDownload} disabled={download}
          style={{
            width: '11rem',
            borderRadius: '0.2rem', height: '2rem',
            marginTop: '-0.4rem', marginLeft: '33rem', cursor: 'pointer',
            color: "white", backgroundColor: download === true ? "grey" : "#86bc26", fontWeight: 'bold'
          }}>Download Summary</button>
        <FileDownloadOutlinedIcon 
        onClick={handleDownload} disabled={download}
         style={{
          width: '3rem',
          borderRadius: '0.2rem', height: '2rem',
          marginTop: '-0.4rem', marginLeft: '-0.5rem', cursor: 'pointer',
          color: "white", backgroundColor: download === true ? "grey" : "#86bc26", fontWeight: 'bold'
        }}></FileDownloadOutlinedIcon>
      </div>
      <div className="excel-tabs">
        {tabs.map((tab, index) => (
          <div key={index} className={`excel-tab ${activeTabIndex === index ? 'active' : ''}`} onClick={() => handleTabClick(index)}>
            <span>{tab.fileName}</span>
            {/* <button onClick={() => handleTabClose(index)}>Close</button> */}
          </div>
        ))}
      </div>
      <div className="excel-preview-container">
        <div className="excel-tags" style={{ maxHeight: '12.15rem', overflowY: 'auto' }}>
          {tabs.length > 0 &&
            tabs[activeTabIndex].sheets.map((sheet, index) => (
              <span
                key={index}
                onClick={() => handleSheetClick(index)}
                className={`excel-tag ${selectedSheetIndex === index ? 'selected' : ''}`}
              >
                {sheet.name}
              </span>
            ))}
        </div>
        {selectedSheetIndex !== null && (
          <div className="out-table-container">
            <table>
              <thead>
                <tr>
                  {tabs[activeTabIndex].sheets[selectedSheetIndex].data[0].map((header, headerIndex) => (
                    <th key={headerIndex}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tabs[activeTabIndex].sheets[selectedSheetIndex].data.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {!pdfFile && <div style={{ color: 'white', marginTop: '5rem', marginLeft: '25rem', height: '10rem' }}>Upload Document to Preview here</div>}

      </div>
      <div className="flex flex-col item-center border-top border-custom-green border-solid border-2 ml-0 mt-8 rounded-lg"

        style={{ width: '95rem', height: '41rem', borderLeft: 'hidden', borderRight: 'hidden', borderBottom: "hidden" }}>

        <span style={{
          marginLeft: '40rem', marginTop: '-1rem', backgroundColor: "#0078D4", color: 'white'
          , width: '14rem', height: '1.8rem', borderRadius: '0.2rem'
          , textAlign: 'center',fontWeight:'bold'
        }}
        >
          Summarized Policy Notes
        </span>
        {loading ? (
          <div className="flex flex-col" style={{ marginTop: '5rem', marginLeft: '45rem', color: 'white', fontWeight: "bold" }}>
            <div style={{ marginLeft: '-2rem', marginBottom: '1rem', color: 'white', fontWeight: "bold" }}>Processing</div>
            <FadeLoader
              // css={override}
              // style={{marginLeft:'-2rem'}}
              color={"#FFFFFF"}
              loading={loading}
              size={5}
              height={7}
              width={3}
              margin={-8} /></div>
        ) : excelData.cols.length !== 0 ? (
          <div className="s3-table-container" style={{ overflowX: 'auto', maxHeight: '20rem', marginTop: '0.8rem' }}>
            <table style={{ tableLayout: 'auto', width: '100%' }}>
              <thead>
                <tr>
                  {excelData.cols.map(col => (
                    <th key={col.key} style={{ textAlign: 'left' }}>{col.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {excelData.rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex} style={{ textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (<></>)}


      </div>
    </div>
  );
}

export default ExcelUploadPreview;
