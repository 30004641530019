//@ts-nocheck
import React, { lazy, Suspense, useEffect } from 'react';
import './MenuPage.css';
import Header from './Header';
import { useLocation } from 'react-router-dom';

const Sidebar = ({ activeItem, onItemClick, downloadedFiles,user }) => {
  const menuItems = [
    { id: 1, label: 'ACORD', color: '#0078D4', component: 'StepperCompEmail' },
    { id: 2, label: 'SOV', color: '#0078D4', component: 'StepperCompSOVEmail' },
    { id: 3, label: 'LR', color: '#0078D4', component: 'StepperCompLREmail' },
  ];
  const [currentActiveItem, setCurrentActiveItem] = React.useState(activeItem);

  const loadComponent = async (component) => {
    const module = await import(`./${component}`);
    return (props) => <module.default {...props} downloadedFiles={downloadedFiles} user={user}/>;
  };

  const handleItemClick = async (item) => {
    setCurrentActiveItem(item.id);
    const Component = await loadComponent(item.component);
    onItemClick(<Component />);
  };

  return (
    <div className="sidebar">
      {menuItems.map((item) => (
        <div key={item.id} style={{ marginBottom: '3.5rem' }}>
          <button
            style={{
              color: 'white',
              fontWeight: 'bold',
              backgroundColor: item.color,
              height: '2.5rem',
              width: '4.5rem',
              borderTopRightRadius: '1.3rem',
              borderBottomRightRadius: '1.3rem',
              cursor: 'pointer',
            }}
            className={`menu-item ${currentActiveItem === item.id ? 'active' : ''}`}
            onClick={() => handleItemClick(item)}
          >
            <div style={{ paddingTop: '0rem' }}>
              <span style={{ marginTop: '3rem' }}>{item.label}</span>
            </div>
          </button>
        </div>
      ))}
    </div>
  );
};

const MainPageEmail = () => {
  const [selectedComponent, setSelectedComponent] = React.useState(null);

  const location = useLocation();
  const downloadedFiles = location.state?.organizedFiles || [];
  const userDetails = location.state?.enteredValues|| [];
  console.log("in main page", downloadedFiles);

  const loadDefaultComponent = async () => {
    const Component = await import('./StepperCompEmail');
    setSelectedComponent(<Component.default downloadedFiles={downloadedFiles} user={userDetails}/>);
  };

  useEffect(() => {
    loadDefaultComponent();
  }, []); // Load default component on initial mount

  const handleItemClick = (component) => {
    setSelectedComponent(component);
  };

  return (
    <>
      <Header />
      <div className="main-page">
        <div className="container">
          <div className="menu-container">
            <Sidebar activeItem={1} onItemClick={handleItemClick} downloadedFiles={downloadedFiles} user={userDetails} />
          </div>
          <div className="content-container">
            {selectedComponent && <Suspense fallback={<div>Loading...</div>}>{selectedComponent}</Suspense>}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainPageEmail;
