//@ts-nocheck
import React, { useState, useEffect } from 'react';
import MainPage from './MainPage';
import Header from './Header';
import FadeLoader from 'react-spinners/FadeLoader';
import { useNavigate } from 'react-router-dom';
import './InitialPage.css';
import Box from '@mui/material/Box';
import AWS from 'aws-sdk';
import email from "./email.png"
import mail from "./mail_2.png"
import mail3 from "./email3.png"
import ExcelUploadPreview from "./ExcelUploadPreview"

import './ExcelUploadPreview.css'; // Import your custom styles
import { useDropzone } from 'react-dropzone';
import { DropzoneArea } from 'material-ui-dropzone';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';

const InitialPage: React.FC = () => {
  const navigate = useNavigate();
  const [downloadedFiles, setDownloadedFiles] = useState([]);
  const [excelData, setExcelData] = useState({
    cols: [],
    rows: [],
  });
  const [excelData2, setExcelTwoData] = useState({
    cols: [],
    rows: [],
  });
  
  const [pdfError, setPdfError] = useState('');

  const [pdfFile, setPdfFile] = useState('');
  const [docType, setDocType] = useState('');
  const [main, setMain] = useState({});
  const [pdfName, setPdfName] = useState();
  const [pdfNameTwo, setPdfNameTwo] = useState();
  const [formData, setFormData] = useState({
    server: '',
    username: '',
    password: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [userValues, setUserValues] = useState<string | null>(null);
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
  const handlePreviewTwo = (files) => {
    if (files.length > 0) {
      const file = files[0];
      console.log(files.map((file) => file.name))
      ExcelRenderer(file, (err, resp) => {
        if (err) {
          console.log(err);
          // setAnalyze(false)
        } else {
          const result = resp.result || resp;
          setExcelTwoData({
            cols: result.cols,
            rows: result.rows,
            // cols: result.cols.slice(1),
            // rows: result.rows.slice(1).map(row=>row.slice(1)),
          });

          // handleFileS3(files[0])
          setPdfNameTwo(files.map((file) => file.name))
          // setAnalyze(true)
        }
      });
    }
  };

  const handlePreview = (files) => {
    if (files.length > 0) {
      const file = files[0];
      console.log(files.map((file) => file.name))
      ExcelRenderer(file, (err, resp) => {
        if (err) {
          console.log(err);
          // setAnalyze(false)
        } else {
          const result = resp.result || resp;
          setExcelData({
            cols: result.cols,
            rows: result.rows,
            // cols: result.cols.slice(1),
            // rows: result.rows.slice(1).map(row=>row.slice(1)),
          });

          // handleFileS3(files[0])
          setPdfName(files.map((file) => file.name))
          // setAnalyze(true)
        }
      });
    }
  };

  const handleUploadClick = (action: string) => {
    navigate('/upload-documents');
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const dropzoneTextStyles = {
    color: 'red !important'
  }
  const simulateAsyncOperation = async () => {
    return new Promise(async (resolve) => {
      // Simulate the asynchronous operation (fetching files)
      while (true) {
        const files = await fetchDownloadedFiles();
        // Check if there are downloaded files
        if (files.length > 0) {
          resolve();
          break;
        }
        // Add a delay before the next attempt (adjust as needed)
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    });
  };
  const isSubmitDisabled = !formData.server || !formData.username || !formData.password;

  const handleSubmit = async () => {
    setShowModal(false);
    handleCloseModal(); // Close the modal first
    setLoading(true);
    const enteredValues = {
      MAIL_SERVER: formData.server,
      MAIL_USERNAME: formData.username,
      MAIL_PASSWORD: formData.password,
    };
    console.log(enteredValues)
    setUserValues(enteredValues)
    // Simulate an asynchronous operation, e.g., submitting data
    await simulateAsyncOperation();

    // Check if there are downloaded files before setting loading to false
    (async () => {
      const [response] = await Promise.all([
        fetch(
          // "https://giwlo9e8vd.execute-api.us-east-1.amazonaws.com/dev",
          // "https://sqhci3zdhtdxlixbvztekzv4vq0eyysc.lambda-url.us-east-1.on.aws/",
          // "https://m35lfkjgncuzwhbib3cnwrkbxe0hblbx.lambda-url.us-east-1.on.aws/",
          "https://8eshrwpqjl.execute-api.us-east-1.amazonaws.com/dev/emailtriaging/",
          {
            method: "POST",
            // 'X-API-KEY': '',
            headers: {
              'X-API-KEY': 'tGHEtYJ1WV2aWhgEBcUTK1MD80cKdEORKF9TAhIe',
              'Accept': "application/json",
              'Content-Type': "application/json"
            },
            body: JSON.stringify(enteredValues)

          })
      ]);
      var data = {};
      const content = await response.json();
      console.log(content)
      const organizedFiles = content["Response "] && content["Response "].reduce((acc, file) => {
        const documentType = file.DocumentType;
        const filenameWithoutPrefix = file.Key.replace('In/' + documentType + '/', '');

        if (!acc[documentType]) {
          acc[documentType] = [];
        }

        acc[documentType].push(filenameWithoutPrefix);
        return acc;
      }, {});
      console.log(organizedFiles)
      // const files = await fetchDownloadedFiles();
      if (organizedFiles && Object.keys(organizedFiles).length > 0) {
        setLoading(false);
        // navigate('/emailtrigger')
        navigate('/emailtrigger', { state: { organizedFiles, enteredValues } });

      }

    })();
  };

  const fetchDownloadedFiles = async () => {
    try {
      const s3 = new AWS.S3({
        accessKeyId: 'AKIA3IXQ24EIEETLGEW5',
        secretAccessKey: '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',
        region: 'us-east-1',
      });

      const params = {
        Bucket: 'openaiform-poc',
        Prefix: 'In/ACORD/',
      };

      const response = await s3.listObjectsV2(params).promise();
      const files = response.Contents.map((file) => {
        // Extract only the file name without the folder path
        const fileName = file.Key.replace('In/ACORD/', '');
        return fileName;
      });

      const filteredFiles = files.filter((file) => file !== "0" && file !== "");

      setDownloadedFiles(filteredFiles);
      return filteredFiles; // Return the filtered files to be used in the async operation

    } catch (error) {
      console.error('Error fetching downloaded files:', error);
      return [];
    }
  };


  return (
    <>
      <Header></Header>
      <div className={`flex flex-wrap justify-around items-center space-x-4 h-screen ${loading ? 'bg-black opacity-75' : ''}`}
        style={{ marginTop: '-5rem' }}>
        <div className="flex flex-row item-center border-2 border-custom-green ml-2 mt-16 rounded-lg"
        style={{ width: '95rem', height: '41rem' }}>
        {/* <div className={`flex justify-between items-center h-screen ${loading ? 'bg-black opacity-75' : ''}`} style={{ marginTop: '-5rem', width: 'full', mx: 'auto' }}> */}
        {/* <div className={`flex justify-center items-center h-screen space-x-48 ${loading ? 'bg-black opacity-75' : ''}`} style={{ marginTop: '-5rem' }}> */}
        {/* <div className={`flex justify-center items-center space-x-16 h-screen ${loading ? 'bg-black opacity-75' : ''}`} style={{ marginTop: '-5rem' }}> */}

     <ExcelUploadPreview></ExcelUploadPreview>
     </div>
      </div>
    </>
  );
};

export default InitialPage;
